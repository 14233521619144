'use client';

// Using this wrapper to allow for dynamic import of ToastHandler
// because it uses hooks that need to be called in the client
// before it is added to the layout server component.

import dynamic from 'next/dynamic';

const ToastHandler = dynamic(() => import('./ToastHandler'), {
  ssr: false,
});

export default function ToastHandlerWrapper() {
  return <ToastHandler />;
}
