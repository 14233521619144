'use client';

// Using this wrapper to allow for dynamic import of PostHogPageView
// because it uses hooks that need to be called in the client
// before it is added to the layout server component.

import dynamic from 'next/dynamic';

const PostHogPageView = dynamic(() => import('./PostHogPageView'), {
  ssr: false,
});

export default function PostHogPageViewWrapper() {
  return <PostHogPageView />;
}
