'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

export function PHProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    posthog.init(
      process.env.NEXT_PUBLIC_POSTHOG_KEY ||
        ('phc_1gVRFlJlWGJg6FWhFoTuFlWwvItpOxMuGIxGSX6mHkU' as string),
      {
        api_host:
          process.env.NEXT_PUBLIC_POSTHOG_HOST ||
          ('https://us.i.posthog.com' as string),
        person_profiles: 'identified_only',
        capture_pageview: true,
        capture_pageleave: false,
        autocapture: false,
      }
    );
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
